/**
 * A basic <li>.
 */

import React from 'react';
import {css} from "@emotion/core";


const list = css`
  list-style-type: none;
  list-style-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAABxVBMVEVHcEz///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9MwLNfAAAAlnRSTlMA/vXWEAUW+QIBTAa9EcVDPBXp/OoE9A19XcM1NNiKk5TwQFKl+0/GWs8O1E4fjwOEhW3hvBlbPU3is1TVOXZp+r9CoLHcHW62u0T9DwhKzoAnnC5jjqeHR3qCytlxMGb2nszljDN7ZbS5mGEi+AnRneAMwJFzaFbapFDQi0nmuqoeB81qLDur8+hgKp91MXQc7S1vVYYhXE6ZAAABm0lEQVQ4y4WSVXfDMAyF1TZtkrXdutKYmakdMzMzMzMzM6N+7+JkO4u7LtNDruT7HduKDCCG7pEBcD7dJ2eegps4OFOhJfdBjdZrPDT99icx5jwv9QVz74D30iwEuvqNqnFekOfbFFIZM+J4F8C/iVoZwkHa91EVUnUgRtCANxZRtReG0wBvLhOVyfZgiPZgVwoF6PtYrSBZZsSbS/JLOrGVAiKjBoSvLSe/oCA/w0ZWkrFU5ptwg8gr5gG84wXJG8JCZMB+GDkAkjAd4A1PxDVDiQzYiZPUYU/7sB9JeUy5DAixclK3Vyx77C2mTrZZBkTj7nc7eklt08NJMoDbs07ISmOA35JqimpzfszSC+1tX5UDMSjRZVi60A4IqpbyVTWuuXsxkFpXK+q6ZRmzxREagimgvkJdZYSWEQzWalYWAeI92W56i8oaxGJU+/LCNDeJ7/HrlID0ND+d+IC2GXf+T2RijqIPejObCIoRnqVgmiKE0TDc38AsJmxp7XN/A1ysBhOiQpVuQJAZ5UtyhlFlACL7/wGifX/yT7NfR/HYwgDZAAAAAElFTkSuQmCC');
  font-size: 20px;
  list-style-position: inside;
  padding: 0;
  margin: 0;
  
  li {
    margin-bottom: 1em;
    border-bottom: 1px dotted white;
    padding-bottom: 1em;
  }
  
  .sep {
    content: "";
    margin: 4em 0;
  }
`;


export function StringListWithIcons(props) {
  return <ul css={list}>
    {props.items.map(value => {
      if (value === '-') {
        return <div className={"sep"} />
      }
      return <li>{value}</li>;
    })}
  </ul>
}

