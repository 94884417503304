/**
 * A box with a background color.
 */

import React from 'react';
import {css} from "@emotion/core";


const style = css`
  padding: 1em;
  background: #9B1030;
  color: white;
`;


export function Box(props) {
  return <div css={style} className={props.className} style={props.style}>
    {props.children}
  </div>
}