import * as React from 'react'
import SinglePageLayout from '../components/singlePageLayout'
import {Box} from '../components/Box'
import {StringListWithIcons} from '../components/StringListWithIcons'
import {css} from '@emotion/core';
import BlockContent from "@sanity/block-content-to-react";
import Img from 'gatsby-image'
import SanityMuxPlayer from 'sanity-mux-player'
import { Button } from '../components/Button'
import { graphql } from 'gatsby'


const JobOpeningPageTemplate = (props) => {
  const page = props.data.page;

  let image;
  if (page.coverImage && page.coverImage.asset) {
    image = <Img 
      style={{height: '100%', width: '100%'}} 
      fluid={page.coverImage.asset.fluid} 
    />;
  }
  else if (page.coverVideo) {
     image = <SanityMuxPlayer
      assetDocument={page.coverVideo.asset}
      autoload={true}
      autoplay={false}
      loop={false}
      muted={false}
      poster={true}
      showControls={true}
    />
  }

  return (
    // @ts-ignore
    <SinglePageLayout
      pageTitle={page.title}
      rightHeaderContent={<div style={{flex: 1, textAlign: 'right'}}>
        <Button href={"mailto:blumen@elsdoerfer.com"} css={css`font-size: 1.2em;`}>
          Jetzt bewerben
        </Button>
      </div>}
      css={css`
        display: grid;
        grid-template-columns: 70% auto;
        grid-template-rows: min-content min-content;
        grid-gap: 1em;
        
        margin: 2em;
        max-width: 1300px;
        align-self: center;
        
        .main {
          grid-column: 1;
          grid-row: 1;
        }
        
        .sidebar {
          grid-column: 2;
          grid-row: span 2;
        }
        
        @media (max-width: 800px) {
          grid-template-columns: 100%;
          
          .sidebar {
            grid-column: 1;
          
          }
        }
      `}
    >
      <div css={css`
          display: flex;
          flex-direction: column;          
        `}
         className={"main"}
      >
        <div style={{flex: 1}}>
          {image}
        </div>
        <Box style={{flex: 1, fontSize: '20px'}}>
          <BlockContent blocks={page.whoWeAre} />
        </Box>
      </div>

      <Box css={css`        
        align-self: flex-start;
      `} className={"sidebar"}>
        <StringListWithIcons items={page.weOffer} />
      </Box>

      <div css={css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      `}>
        {page.youOffer.map((item, idx) => {
          return <Img
            key={idx}
            fluid={item.image.asset.fluid}
            backgroundColor={`#040e18`}
            style={{maxHeight: '400px'}}
          >
          </Img>
        })}
      </div>
    </SinglePageLayout>
  )
}

export default JobOpeningPageTemplate;



export const pageQuery = graphql`
  query JobOpeningBySlug($id: String!) {
    page: sanityJobOpening(id: { eq: $id }) {
      id,
      title,
      whoWeAre: _rawWhoWeAre,
      weOffer,
      youOffer {
        title,
        image { 
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      },
      coverVideo {
         asset {
            status,
            assetId,
            playbackId,
            thumbTime,
          }
      },
      coverImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`