import React from 'react';
import Helmet from 'react-helmet';

import Header from './header'
import './layout.css'


const SinglePageLayout = ({ children, pageTitle, className, rightHeaderContent }) => (
  <>
    <Helmet
      title={"Blumen Elsdörfer"}
      meta={[
        { name: 'description', content: 'Sample' },
        { name: 'keywords', content: 'sample, something' },
      ]}
    >
      <html lang="en" />
      <link rel="stylesheet" href="https://use.typekit.net/unq3aox.css" />
    </Helmet>
    <Header
      pageTitle={pageTitle}
      showNavBar={false}
      customRightContent={rightHeaderContent}
    />
    <div style={{flex: 1}} className={className}>
      {children}
    </div>
  </>
)

export default SinglePageLayout